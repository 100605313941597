<template>
  <div>
    <h1 class="text-h4">Управление скидками</h1>
    <v-divider class="my-2"></v-divider>
    <v-form ref="form" v-model="form.valid" :disabled="busy" @submit.prevent="submitForm">
      <v-card>
        <v-card-title>Сгенерировать одноразовые коды</v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="form.data.discountId"
            label="Программа скидок"
            item-text="promotion"
            item-value="id"
            :items="discounts"
            clearable
            return-object
            :rules="form.rule.discountId"
          />
          <v-text-field
            v-model="form.data.count"
            label="Количество"
            counter="4"
            maxlength="4"
            :rules="form.rule.count"
            hint="Количество кодов, которые могут созданы"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :to="{name: 'discount-code.list'}">Отмена</v-btn>
          <v-btn color="primary" :disabled="!form.valid" type="submit">Отправить</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import { ruleNumeric, ruleRequired } from '@/utils/FormRules'
import { mapActions, mapState } from 'vuex'
import ToastService from '@/service/ToastService'
import DiscountCodeGenerateForm from '@/model/discount/DiscountCodeGenerateForm'

export default {
  ...dashboardPageCommonAttributes,
  name: 'DiscountCodeGeneratePage',
  data() {
    return {
      form: {
        valid: true,
        data: {
          discountId: null,
          count: null
        },
        rule: {
          discountId: [ruleRequired()],
          code: [ruleRequired()],
          count: [ruleRequired(), ruleNumeric(), (v) => (v > 0 && v < 1000) || 'Значение должно быть не более 1000']
        }
      }
    }
  },

  computed: {
    ...mapState('discount_code_generate', ['busy', 'discounts'])
  },
  created() {
    this.fetchData()
      .then(() => {
      })
  },
  methods: {
    ...mapActions('discount_code_generate', ['fetchData', 'generateDiscountCodes']),
    submitForm() {
      const discountId = this.form.data.discountId?.id ?? null

      const form = {
        ...DiscountCodeGenerateForm.create(this.form.data),
        count: parseInt(this.form.data.count),
        discountId
      }

      this.generateDiscountCodes({ form })
        .then(() => this.$router.push({ name: 'discount-code.list' }))
        .catch((error) => {
          console.warn(error)
          ToastService.error('Что-то пошло не так')
        })
    },
    revalidateForm() {
      this.$refs.form.validate()
    }
  }
}
</script>
