export default class DiscountCodeGenerateForm {
  constructor(data) {
    this.discountId = data?.discountId
    this.count = data?.count
  }

  static create(data) {
    return new DiscountCodeGenerateForm(data)
  }
}
